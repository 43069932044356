<template>
  <div class="bg-white">
    <!-- Mobile menu -->
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="fixed inset-0 flex z-40 lg:hidden" @close="open = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <div class="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
            <div class="px-4 pt-5 pb-2 flex">
              <button type="button" class="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400" @click="open = false">
                <span class="sr-only">Close menu</span>
                <Close32 class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            <!-- Links -->
            <TabGroup as="div" class="mt-2">
              <div class="border-b border-gray-200">
                <TabList class="-mb-px flex px-4 space-x-8">
                  <Tab as="template" v-for="category in navigation.categories" :key="category.name" v-slot="{ selected }">
                    <button :class="[selected ? 'text-orange-600 border-orange-600' : 'text-gray-900 border-transparent', 'flex-1 whitespace-nowrap py-4 px-1 border-b-2 text-base font-medium']">
                      {{ category.name }}
                    </button>
                  </Tab>
                </TabList>
              </div>
              <TabPanels as="template">
                <TabPanel v-for="category in navigation.categories" :key="category.name" class="pt-10 pb-8 px-4 space-y-10">
                  <div class="grid grid-cols-2 gap-x-4">
                    <div v-for="item in category.featured" :key="item.name" class="group relative text-sm">
                      <div class="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden group-hover:opacity-75">
                        <img :src="item.imageSrc" :alt="item.imageAlt" class="object-center object-cover" />
                      </div>
                      <router-link :to="item.href" class="mt-6 block font-medium text-gray-900">
                        <span class="absolute z-10 inset-0" aria-hidden="true" />
                        {{ item.name }}
                      </router-link>
                      <!--<p aria-hidden="true" class="mt-1">Shop now</p>-->
                    </div>
                  </div>
                  <div v-for="section in category.sections" :key="section.name">
                    <p :id="`${category.id}-${section.id}-heading-mobile`" class="font-medium text-gray-900">
                      {{ section.name }}
                    </p>
                    <ul role="list" :aria-labelledby="`${category.id}-${section.id}-heading-mobile`" class="mt-6 flex flex-col space-y-6">
                      <li v-for="item in section.items" :key="item.name" class="flow-root">
                        <router-link :to="item.href" class="-m-2 p-2 block text-gray-500" @click="open = false">
                          {{ item.name }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </TabPanel>
              </TabPanels>
            </TabGroup>

            <div class="border-t border-gray-200 py-6 px-4 space-y-6">
              <div v-for="page in navigation.pages" :key="page.name" class="flow-root">
                <router-link :to="page.href" class="-m-2 p-2 block font-medium text-gray-900">{{ page.name }}</router-link>
              </div>
            </div>

            <div class="border-t border-gray-200 py-6 px-4 space-y-6">
              <div class="flow-root">
                <router-link to="/profile" class="-m-2 p-2 block font-medium text-gray-900">My account</router-link>
                <a href @click.prevent="logOut" class="-m-2 p-2 block font-medium text-gray-900">Sign out</a>
              </div>
            </div>

          </div>
        </TransitionChild>
      </Dialog>
    </TransitionRoot>

    <header class='relative bg-white'>
      <!--<p class="bg-orange-600 h-10 flex items-center justify-center text-sm font-medium text-white px-4 sm:px-6 lg:px-8">
        Vul je s.v.p.<router-link to="/profile" class="m-1 underline">gebruikersprofiel</router-link> aan.
      </p>-->

      <nav aria-label="Top" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="border-b border-gray-200">
          <div class="h-16 flex items-center">
            <button type="button" class="bg-white p-2 rounded-md text-gray-400 lg:hidden" @click="open = true">
              <span class="sr-only">Open menu</span>
              <Menu32 class="h-6 w-6" aria-hidden="true" />
            </button>

            <!-- Logo -->
            <div class="ml-4 flex lg:ml-0">
              <router-link to="/">
                <span class="sr-only">McCoy</span>
                <img class="h-8 w-auto" src="@/assets/logo-web.svg" alt="McCoy" />
              </router-link>
            </div>

            <!-- Flyout menus -->
            <PopoverGroup class="hidden lg:ml-8 lg:block lg:self-stretch">
              <div class="h-full flex space-x-8">
                <Popover v-for="category in navigation.categories" :key="category.name" class="flex" v-slot="{ open, close }">
                  <div class="relative flex">
                    <PopoverButton :class="[open ? 'border-orange-600 text-orange-600' : 'border-transparent text-gray-700 hover:text-gray-800', 'relative z-10 flex items-center transition-colors ease-out duration-200 text-sm font-medium border-b-2 -mb-px pt-px']">
                      {{ category.name }}
                    </PopoverButton>
                  </div>

                  <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <PopoverPanel class="absolute top-full inset-x-0 text-sm text-gray-500">
                      <!-- Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow -->
                      <div class="absolute inset-0 top-1/2 bg-white shadow" aria-hidden="true" />

                      <div class="relative bg-white z-30">
                        <div class="max-w-7xl mx-auto px-8">
                          <div class="grid grid-cols-2 gap-y-10 gap-x-8 py-16">
                            <div class="col-start-2 grid grid-cols-2 gap-x-8">
                              <div v-for="item in category.featured" :key="item.name" class="group relative text-base sm:text-sm">
                                <div class="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden group-hover:opacity-75">
                                  <img :src="item.imageSrc" :alt="item.imageAlt" class="object-center object-cover" />
                                </div>
                                <a :href="item.href" class="mt-6 block font-medium text-gray-900">
                                  <span class="absolute z-10 inset-0" aria-hidden="true" />
                                  {{ item.name }}
                                </a>
                                <!--<p aria-hidden="true" class="mt-1">Shop now</p>-->
                              </div>
                            </div>
                            <div class="row-start-1 grid grid-cols-3 gap-y-10 gap-x-8 text-sm">
                              <div v-for="section in category.sections" :key="section.name">
                                <p :id="`${section.name}-heading`" class="font-medium text-gray-900">
                                  {{ section.name }}
                                </p>
                                <ul role="list" :aria-labelledby="`${section.name}-heading`" class="mt-6 space-y-6 sm:mt-4 sm:space-y-4">
                                  <li v-for="item in section.items" :key="item.name" class="flex">
                                    <router-link :to="item.href" class="hover:text-gray-800" @click="close">
                                      {{ item.name }}
                                    </router-link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </PopoverPanel>
                  </transition>
                </Popover>

                <router-link v-for="page in navigation.pages" :key="page.name" :to="page.href" class="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800">{{ page.name }}</router-link>
              </div>
            </PopoverGroup>

            <div class="ml-auto flex items-center">
              
              <NotificationBell />
              
              <div class="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                <!-- <router-link to="/profile" class="text-sm font-medium text-gray-700 hover:text-gray-800">My account</router-link> -->
                <!-- <span class="h-6 w-px bg-gray-200" aria-hidden="true" /> -->
                <!-- <a href @click.prevent="logOut" class="text-sm font-medium text-gray-700 hover:text-gray-800">Sign out</a> -->
                <AuthLink />

              </div>

              <!-- Search -->
              <div class="flex lg:ml-6">
                <a href="#" class="p-2 text-gray-400 hover:text-gray-500">
                  <span class="sr-only">Search</span>
                  <Search32 class="w-6 h-6" aria-hidden="true" />
                </a>
              </div>

              <!-- Cart -->
              
            </div>
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from "vuex";
import NotificationBell from '../components/admin/NotificationBell'

import {
  Dialog,
  DialogOverlay,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { Close32, Search32, Menu32 } from '@carbon/icons-vue';
import AuthLink from '../components/AuthLink.vue';

export default {
  components: {
    NotificationBell,
    Dialog,
    DialogOverlay,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    TransitionChild,
    TransitionRoot,
    Close32,
    Search32,
    Menu32,
    AuthLink,
  },
  setup() {
    const account = ref({});
    const open = ref(false)
    const store = useStore();
    const currentUser = computed(() => store.state.user);
    let primaryEditorial, secondaryEditorial = []
    let cvAdmin = {}
    
    if (currentUser.value.idTokenClaims && currentUser.value.idTokenClaims.roles && (currentUser.value.idTokenClaims.roles.includes('Portal.Admin') || currentUser.value.idTokenClaims.roles.includes('Portal.Editor'))) {
      primaryEditorial = [
        { name: 'Updates', href: '/news_items' },
        { name: 'Pages', href: '/pages' },
        { name: 'McAdemy', href: '/mcademy_items' },
        { name: 'People', href: '/people' },
        { name: 'Careers', href: '/jobs' },
        { name: 'Success stories', href: '/cases' },
        { name: 'BusinessUnits', href: '/business_units' },
        { name: 'Homepage Slides', href: '/homepageitems' },
      ]
      secondaryEditorial = [
        { name: 'Hubspot campagnes', href: '/hubspot' },
        { name: 'Redirects', href: '/redirects' },
        { name: 'Quotes', href: '/quotes' },
        { name: 'Logos', href: '/client_logos' },
        { name: 'Call To Actions', href: '/call_to_actions' },
        { name: 'Popups', href: '/leaders' },
        { name: 'Videos', href: '/videos' },
        { name: 'Client logo\'s', href: '/client_logos' },
      ]
    }

    if (currentUser.value.idTokenClaims && currentUser.value.idTokenClaims.roles && (currentUser.value.idTokenClaims.roles.includes('Portal.Admin') || currentUser.value.idTokenClaims.roles.includes('Portal.Sales'))) {
      cvAdmin = {
        id: 'manage_cv',
        name: 'CV Sales',
        items: [
          { name: 'Manage', href: "/cv/manage" }
        ],
      }
    }
      
    const navigation = {
      categories: [],
      pages: [
        { name: 'Documentatie', href: '/documentation' },
        // { name: 'Stores', href: '#' },
      ],
    }

    if (currentUser.value.idTokenClaims && currentUser.value.idTokenClaims.roles && (currentUser.value.idTokenClaims.roles.includes('Portal.Admin') || currentUser.value.idTokenClaims.roles.includes('Portal.Editor'))) {
      navigation.categories.push({
        id: 'website',
        name: 'Website',
        featured: [
          {
            name: '',
            href: '#',
            imageSrc: '/web1.jpg',
            imageAlt: 'McCoy portal',
          },
          {
            name: '',
            href: '#',
            imageSrc: '/web2.jpg',
            imageAlt: 'McCoy portal',
          },
        ],
        sections: [
          {
            id: 'main_content',
            name: 'Main content',
            items: primaryEditorial,
          },
          {
            id: 'additional',
            name: 'Secondary',
            items: secondaryEditorial,
          },
          {
            id: 'utilities',
            name: 'Utilities',
            items: [
              { name: 'Tags', href: '/tags' },
              { name: 'JobTags', href: '/job_tags' },
              { name: 'Settings', href: '/settings' },
            ],
          },
        ],
      })
    }

    if (currentUser.value.idTokenClaims && currentUser.value.idTokenClaims.roles && currentUser.value.idTokenClaims.roles.includes('Portal.Admin')) {
      navigation.categories.push({
        id: 'administration',
        name: 'Beheer',
        featured: [
          {
            name: '',
            href: '#',
            imageSrc: '/web5.jpg',
            imageAlt: 'McCoy portal',
          },
          {
            name: '',
            href: '#',
            imageSrc: '/web3.jpg',
            imageAlt: 'McCoy portal',
          },
        ],
        sections: [
          {
            id: 'general',
            name: 'Algemeen',
            items: [
              { name: 'Intern nieuws', href: "/internal_updates/" },
              { name: 'Gebruikers', href: "/users/" },
            ],
          },
          {
            id: 'additional',
            name: 'Instellingen',
            items: [
              { name: 'Settings', href: "/settings" },
              
            ],
          },
        ],
      })
    }

    if (currentUser.value) {    
      navigation.categories.push({
        id: 'cv',
        name: 'CV',
        featured: [
          {
            name: '',
            href: '#',
            imageSrc: '/web6.jpg',
            imageAlt: 'McCoy portal',
          },
          {
            name: '',
            href: '#',
            imageSrc: '/web4.jpg',
            imageAlt: 'McCoy portal',
          },
        ],
        sections: [
          {
            id: 'my_cv',
            name: 'My CV',
            items: [
              { name: 'Overview', href: "/cv/" + currentUser.value.idTokenClaims.oid },
              { name: 'Manage CV varianten', href: "/cv/resumes/" + currentUser.value.idTokenClaims.oid },
              { name: 'Skills', href: "/cv/skills/" + currentUser.value.idTokenClaims.oid },
              { name: 'Languages', href: "/cv/languages/" + currentUser.value.idTokenClaims.oid },
              { name: 'Education', href: "/cv/education/" + currentUser.value.idTokenClaims.oid },
              { name: 'Training', href: "/cv/training/" + currentUser.value.idTokenClaims.oid },
              { name: 'Projects', href: "/cv/projects/" + currentUser.value.idTokenClaims.oid },
              { name: 'References', href: "/cv/references/" + currentUser.value.idTokenClaims.oid },
            ],
          },
          {
            id: 'generic_cv',
            name: 'CV Tools',
            items: [
              { name: 'Customers', href: '/cv/clients' }
            ],
          },
          cvAdmin
        ],
      })
    }

    return {
      navigation,
      open,
      account
    }
  },
  methods: {
    handleNavigation() {
      this.$emit("navigated");
      //this.open = false
    }
  },
}
</script>