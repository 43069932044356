import axios from "axios";
import authHeader from "../../auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

class MessageService {
  
  getMessages(page, limit, query) {
    if (query) {
      return axios.get(API_URL + `admin/messages?page=${page}&limit=${limit}&q=${query}`, { headers: authHeader() });
    } else {
      return axios.get(API_URL + `admin/messages?page=${page}&limit=${limit}`, { headers: authHeader() });
    }
  }

  getAllUsers() {
    return axios.get(API_URL + `admin/messages/users`, { headers: authHeader() });
  }

  saveMessage(message, userIds) {
    return axios.post(API_URL + `admin/messages`, {message, userIds}, { headers: authHeader() });
  }
  
  deleteMessage(hash) {
    return axios.delete(API_URL + `admin/messages/${hash}`, { headers: authHeader() });
  }

  readMessage(hash) {
    return axios.get(API_URL + `admin/messages/${hash}`, { headers: authHeader() });
  }

  checkForUnreadMessages() {
    return axios.get(API_URL + `admin/messages/unread`, { headers: authHeader() });
  }

}

export default new MessageService();
