<template>
  <button @click="goToMessages" type="button" class="bg-white text-gray-800 focus:ring-offset-gray-800 relative p-1 mr-4 rounded-full focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-gray-900">
    <span class="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1" v-if="messages > 0">
      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
      <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
    </span>
    <span class="sr-only">View notifications</span>
    <Notification32 v-if="!messages" class="h-6 w-6" aria-hidden="true" />
    <NotificationNew32 v-else class="h-6 w-6" aria-hidden="true" />
  </button>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { Notification32, NotificationNew32 } from '@carbon/icons-vue';
import { useRouter } from 'vue-router';

import MessageService from '../../services/admin/messages/service'
export default {
  components: {
    Notification32,
    NotificationNew32
  },
  setup() {
    const router = useRouter();
    const updater = ref(null);
    const messages = ref([]);

    const fetchUnreadMessages = async function() {

      MessageService.checkForUnreadMessages().then(({data})=> {
        messages.value = data.unreadCount;
      },(error) => {
        if (error.response.status === 401)
          router.push(`/auth`);
      }
      );
    }

    onMounted(() => {
      fetchUnreadMessages();
      updater.value = setInterval(async () => {
        fetchUnreadMessages();
      }, 20000); // every 20 seconds
    });

    onBeforeUnmount(() => {
      console.log('unmount')
      clearInterval(updater.value);
    });
    
    return {
      messages
    }
  },
  methods: {
    goToMessages() {
      this.$router.push(`/messages`);
    }
  }
}
</script>