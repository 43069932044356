<template>
  <div>
    <div v-if="currentUser">
      <router-link to="/profile" class="-m-2 p-2 block font-medium text-gray-900">{{currentUser.account.name}}</router-link>
    </div>
    <div v-else @click="SignIn()" class="cursor-pointer">sign in</div>
  </div>
  
</template>
<script>

export default {
  name: 'HeaderBar',
  data() {
    return {
      account: undefined
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.user;
    },
    token() {
      return this.$store.state.accesstoken;
    },
  },
  methods: {
    async SignIn() {
      this.$router.push(`/auth`);
    }
  },
};
</script>